import Image from 'next/image'
import { withErrorBoundary } from '@/components/ErrorBounddary'

const Device = () => {
  return (
    <div className="container relative flex min-h-screen items-center justify-center pt-48">
      <div className="absolute left-96 top-24 hidden h-24 w-24 rounded-full lg:block lg:bg-orange-400" />
      <div className="grid h-full w-full grid-cols-1 gap-12 lg:grid-cols-5">
        <div className="col-span-1 flex h-full flex-col items-center justify-center gap-8 text-white lg:col-span-2 lg:items-start">
          <div className="text-center lg:text-left">
            <div className="text-3xl font-bold lg:text-5xl">AI</div>
            <div className="mt-6 text-3xl font-bold lg:text-5xl">复式发牌机</div>
          </div>
          <div className="text-lg opacity-80">
            通过机器视觉，支持绝大多数主流牌张精准识别，带有错牌、漏牌提示，建设均衡牌力系统，一键发出双方均衡的牌型。二代机轻巧便携，可在 1 分钟内分发 2 副扑克。
          </div>
          {['机器识别各类牌张', '智能分拣·均衡牌力', '轻巧快速·30s/副'].map((v, i) => (
            <div key={v} className={`flex w-96 items-center gap-2 rounded-lg bg-white px-6 py-3 text-black ${i === 1 ? 'lg:ml-24' : ''}`}>
              <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-sky-50">
                <Image src={`/device/${i + 1}.png`} alt="" width={50} height={50} />
              </div>
              <div className="text-xl text-zinc-700">{v}</div>
            </div>
          ))}
        </div>
        <div className="col-span-1 m-auto aspect-square w-full items-center justify-center rounded-full lg:col-span-3 lg:flex lg:bg-orange-400">
          <video playsInline autoPlay className="h-auto w-full rounded-lg py-16 lg:p-16" controls={false} loop muted poster="/video-cover.jpeg">
            <source src="https://assets.ruilisi.com/YCqr0kB1rcJQaiLc0Fo2iA==" />
            <track kind="captions" />
          </video>
        </div>
      </div>
    </div>
  )
}
export default withErrorBoundary(Device)
