import { get } from '@/utils/request'
import useSWR from 'swr'
import { commonSWROptions } from './useAdmin'

const useLogin = () => {
  const { data, mutate } = useSWR('login', () => get('users/settings/gd_login'), { fallbackData: {}, ...commonSWROptions })
  return {
    isLogin: data?.is_login,
    mutate,
    current_user: data?.current_user ?? {},
    isVip: data?.is_vip,
    isDeviceAdmin: data?.is_device_admin,
    isHighPermission: data?.is_high_permission,
    vipLevel: data?.vip_level,
    qingdao_user: data?.qingdao_user ?? {},
    is_device_admin: data?.is_device_admin,
  }
}

export default useLogin
