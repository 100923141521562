import { useRouter } from 'next/router'
import React from 'react'
import Image from 'next/image'
import { withErrorBoundary } from '@/components/ErrorBounddary'

const Footer = () => {
  const router = useRouter()
  const company = '苏州瑞立思科技有限公司'

  return (
    <div className="clear-both bg-black">
      <div className="flex flex-col justify-around py-5 sm:flex-row">
        <div className="footer-headline px-2">
          {[
            ['隐私政策', 'https://xiemala.com/s/-_BdW4'],
            ['服务协议', 'https://xiemala.com/s/zZDQd9'],
          ].map((v) => (
            <span role="presentation" className="mr-4 cursor-pointer text-gray-400" onClick={() => window.open(v[1])} key={v[1]}>
              {v[0]}
            </span>
          ))}
          <span role="presentation" className="mr-4 cursor-pointer text-gray-400" onClick={() => router.push('/about_us')}>
            关于我们
          </span>
          <span className="text-gray-400">
            电话：
            <a href="tel:0512-65782012" className="text-gray-400 hover:text-gray-200">
              0512-65782012
            </a>
          </span>
          <div className="text-gray-400">
            商务合作：
            <a href="tel:15370036553" className="text-gray-400 hover:text-gray-200">
              15370036553
            </a>
            （臧经理）
          </div>
          <div className="text-gray-400">地址：苏州市高铁新城南天成路28号高清传媒大厦112</div>
          <div className="text-gray-400">灵缇互娱旗下的灵缇平台由{company}独立研发，采用业内领先的网络技术，带给您不同的赛事体验</div>
          <div className="flex justify-start gap-3 text-gray-400">
            <div className="cursor-text">友情链接：</div>
            <a target="_blank" rel="noreferrer" href="https://xiemala.com" className="text-gray-400 hover:text-gray-200">
              协码啦
            </a>
            <a target="_blank" rel="noreferrer" href="https://esheeps.com" className="text-gray-400 hover:text-gray-200">
              电子绵羊
            </a>
          </div>
          <div className="flex justify-start text-gray-400">
            © {company} 版权所有
            <a target="blank" href="https://beian.miit.gov.cn/" className="ml-4 text-gray-400 hover:text-gray-200">
              苏ICP备19037090号-7
            </a>
          </div>
        </div>
        <div className="footer-headline px-2">
          <div className="grid grid-cols-2 gap-3 md:grid-cols-4">
            <div>
              <Image className="mx-auto flex rounded-sm" src="/shop-qrcode.jpeg" alt="" width={100} height={100} />
              <div className="text-center text-gray-400">商城小程序</div>
            </div>
            <div>
              <Image className="mx-auto flex rounded-sm" src="/mini-qrcode.jpg" alt="" width={100} height={100} />
              <div className="text-center text-gray-400">小程序</div>
            </div>
            <div>
              <Image className="mx-auto flex rounded-sm" src="/lingti-wx-qrcode.jpg" alt="" width={100} height={100} />
              <div className="text-center text-gray-400">关注我们</div>
            </div>
            <div>
              <Image className="mx-auto flex rounded-sm" src="/form-qrcode.png" alt="" width={100} height={100} />
              <div className="text-center text-gray-400">商务合作</div>
            </div>
          </div>
          <div className="flex justify-start md:justify-end">
            <a target="cyxyv" href="https://v.yunaq.com/certificate?domain=www.lingti.com&from=label&code=90030" className="mr-4">
              <img src="/footer/trade.png" alt="" className="h-10" />
            </a>
            <a target="blank" href="//si.trustutn.org/info?sn=286210222040309988484&certType=1">
              <img src="/footer/identify.png" alt="" className="h-10" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundary(Footer)
